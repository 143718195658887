/*------------------------------------*\
  #COMPAT -- Search
\*------------------------------------*/

.c-search__input {
    &:focus {
        border-color: $color-black; // {1}

        + .c-input__inline-label {
            border-color: $color-black; // {2}
            background-color: $color-grey-xlight; // {3}
        }
    }
}

.c-tile.c-search__result {
    width: 100%;
}

.c-search__results {
    flex-basis: 73%;
}

.c-search__help {
    flex-basis: 20%;
}

.c-search__resultDesc {
    color: $color-black;

    a & {
        color: $color-black;
    }
}
