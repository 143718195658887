/*------------------------------------*\
  #COMPAT -- Headings
\*------------------------------------*/

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    color: $color-black;
}

.heading--primary {
    color: $color-brand-primary;
}

