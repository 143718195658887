/*------------------------------------*\
  #COMPAT -- Button
\*------------------------------------*/

.c-btn {
    color: $color-btn-text;
    background-color: $color-btn-background;

    @include hocus() {
        color: $color-btn-text-hover;
        background-color: $color-btn-background-hover;
    }

    &:disabled {
        background-color: $color-grey-light;

        @include hocus() {
            color: $color-btn-text;
        }
    }
}

.c-btn--white {
    background-color: $color-white;
    color: $color-black;

    @include hocus() {
        background-color: $color-grey-xlight;
        color: lighten($color-black, 5%);
    }
}


.c-btn--black {
    background-color: $color-black;

    @include hocus() {
        background-color: $color-grey-xdark;
    }
}

.c-btn__readmore {
    background-color: $color-white;
    color: $color-brand-primary;
    padding-top: 1.2em;
    padding-bottom: 1em;

    @include hocus() {
        background-color: $color-brand-primary;
        color: $color-white;
    }
    
    > i {
        font-size: 0.85em;
        float: right;
    }

    .c-tile__article & {
        border-color: $color-btn-background;

        @include hocus() {
            border-color: $color-btn-background-hover;
        }
    }
}

