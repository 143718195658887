/*------------------------------------*\
  #RESPONSIVE TABLES
\*------------------------------------*/

.c-table__container {
    table {
        background-color: $color-white;
    }

    tr {
        border-bottom-color: $color-brand-primary;
    }


    td, th {
        border-left-color: $color-brand-primary;
    }

    .c-table__firstcell {
        border-right-color: $color-brand-primary;
    }
}

.c-table__firstcell--spacer {
    color: $color-white;
}

.c-tablestyle--bg {
    table {
        background-color: $color-brand-primary;
        border-color: $color-brand-primary;
    }

    tr {
        border-bottom-color: $color-white;
    }

    td, th {
        border-left-color: $color-white;
    }

    .c-table__firstcell {
        border-right-color: $color-white;
    }
}

