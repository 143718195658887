/*------------------------------------*\
  #Z-INDEX
\*------------------------------------*/

$z-index__normal-elements: 1;
$z-index__above-normal: 10;


$z-index__base-navigation: 100;
$z-index__base-navigation_submenu: 110;

$z-index__base-navigation_mobile: 300;
