/*------------------------------------*\
  #COMPAT -- forms
\*------------------------------------*/

.c-input__container {
    label {
        color: $color-white; // {1}

        @include hocus() {
            color: $color-grey-xlight; // {2}
        }
    }
}
.c-input__inline-label {
    background-color: $color-white;  // {3}
    color: $color-grey; // {4}

    @include hocus() {
        color: $color-black; // {5}
        background-color: $color-grey-xlight; // {6}
    }
}

.c-input__checkboxlabel {
    &::before {
        border: 1px solid $color-grey; // {7}
    }

    .c-input__checkbox:checked + &::before {
        border-color: $color-neutral; // {8}
    }
}

.c-input__radiolabel {
    &::before {
        border: 1px solid $color-grey; // {7}
    }

    .c-input__radio:checked + &::before {
        border-color: $color-neutral; // {8}
    }
}
