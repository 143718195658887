/*------------------------------------*\
  #COMPAT -- Article
\*------------------------------------*/

.c-article {
    strong {
        color: $color-brand-primary;
    }
}

.c-article__mainimage {
    max-width: 90vw;
}
