/*----------------------------------*\
  #MQ
\*----------------------------------*/

///
// Pre-configuration for Sass MQ.
 //

$mq-breakpoints: (
  xtiny: 320px,
  tiny: 480px,
  xsmall: 600px,
  small: 720px,
  medium: 1024px,
  large: 1280px,
  xlarge: 1600px,
);
