/*------------------------------------*\
  #COMPAT -- Readmore
\*------------------------------------*/

.c-readmore {
    @include mq($from: tiny) {
        flex-basis: 48%;
    }

    @include mq($from: medium) {
        flex-basis: 31%;
    }

    @include mq($from: large) {
        flex-basis: 23%;
    }
}
