///*------------------------------------*\
//    #MIXINS
//\*------------------------------------*/

// Global mixins available to the entire project. Define file-specific
// mixins inside the file to which they relate.


// Mixin to create a declaration whose value is a rem unit. Also provide pixel fallback.
//
// .simple-usage {
//   @include rem(padding, 10px);
// }

@mixin rem($property, $value) {
  #{$property}: $value;
  #{$property}: rem($value);
}

// Mixin to drop micro clearfix into a selector.
//
// .simple-usage {
//   @include clearfix();
// }
//
// .detailed-usage {
//   @include clearfix(important);
// }

@mixin clearfix($important: false) {

  // Determine whether or not to make declarations important.
  @if ($important == false) {
    $important: null;
  } @else {
    $important: !important;
  }

  &:after {
    content: "" $important;
    display: table $important;
    clear: both $important;
  }

}

// usage:
// .classname {
//    @include hocus() {
//      focus/hover styles
//    }
// }
// Generate `:hover` and `:focus` styles in one go.
@mixin hocus() {
  &:hover,
  &:focus {
    @content;
  }
}
// addition to pocus, also generate active styles
// use in the same way
@mixin pocus() {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

@mixin default-transition() {
  transition: all $default-transition-timing ease-out;
}
@mixin specific-transition($propertyList: all) {
  $list1: "";
  $iteration: false;
  @each $property in $propertyList {
    $seperator: ", ";
    @if($iteration == "false") {
      $seperator: "";
    }
    $list1: $list1 + $property + " #{$default-transition-timing} ease-out" + $seperator;
    $iteration: "true";
  }
  transition: #{$list1};
}