/*------------------------------------*\
  #COMPAT -- Tiles
\*------------------------------------*/

.c-tile {
    box-shadow: -1px -1px 3px 0px rgba(0,0,0,0.2), 1px 1px 5px 0px rgba(0,0,0,0.25);
    display: block;

    @include mq($from: small) {
        flex-basis: auto;
        width: calc(50% - #{$global-spacing-unit-em}); // {1}
    }

    @include mq($from: large) {
        flex-basis: auto;
        width: calc(33.333% - #{$global-spacing-unit-em}); // {1}
    }

    @include mq($from: xlarge) {
        flex-basis: auto;
        width: calc(25% - #{$global-spacing-unit-em}); // {1}
    }

    @include hocus() {
        .c-btn {
            background-color: $color-btn-background-hover; // {2}
            color: $color-btn-text-hover; // {3}
        }
    }
}

.c-tile--total4 .c-tile--num4 {
    @include mq($from: large, $until: xlarge) {
        display: flex;
    }
}

.c-tile__shortdesc {
    color: $color-black;
}

.c-tile__head {
    > h1,
    > h2 {
        color: $color-brand-primary; // {4}
    }
}

.c-tile__article {
    .c-flag {
        height: 5em;
    }

    .c-btn__readmore {
        width: calc(100% + 3em);
    }

    @include hocus() {
        .c-btn__readmore {
            border-color: $color-btn-background-hover; // {5}
        }
    }
}


