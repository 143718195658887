/*------------------------------------*\
  #COMPAT -- input elems
\*------------------------------------*/

input,
textarea,
select,
%input {
    border: 1px solid $color-grey;

    .c-form--error & {
        border-color: $color-warning; // {2}
    }

    .c-form--ok & {
        border-color: $color-positive; // {3}
    }
}
