/*------------------------------------*\
  #COMPAT -- Links
\*------------------------------------*/

a {
    color: $color-links;

    @include pocus() {
        color:  $color-links-hover;
    }
}

