/*------------------------------------*\
  #COMPAT -- Size
\*------------------------------------*/

$sizeDivider: 12;

.o-size {
    @each $breakpointname, $value in $mq-breakpoints {
        @include mq($from: $breakpointname) {
            @for $number from 1 through $sizeDivider {
                &__#{$breakpointname}-#{$number} {
                    flex-basis: 1/$sizeDivider*$number * 100% - 2%;
                }
                &__#{$breakpointname}-#{$number}--margin {
                    flex-basis: calc(#{1/$sizeDivider*$number * 100%} - #{$global-spacing-unit-em}  - 2%);
                }
            }
            &__#{$breakpointname}-auto {
                flex-basis: auto;
            }
            &__grow-from-#{$breakpointname} {
                flex-grow: 1;
            }
        }
    }
}
