/*------------------------------------*\
  #Richtables
\*------------------------------------*/

.c-richtable {
    background-color: #f5f5f5;
}

.c-richtable__rowend {
    border-bottom: 1px solid #fff;
}

.c-richtable__cell {
    border-left: 1px solid #fff;
}
