/*------------------------------------*\
  #COMPAT -- Page foot
\*------------------------------------*/

.c-page-foot--secondary {
    a {
        color: $color-white; // {1}

        @include hocus() {
            color: $color-grey-light; // {2}
        }
    }

    background-color: $color-brand-primary; // {3}
    color: $color-white; // {4}
}
