/*------------------------------------*\
  #COMPAT -- Breadcrumbs
\*------------------------------------*/

.c-breadcrumbs {
    i {
        color: $color-brand-primary;
    }

    a {
        color: $color-grey-dark;

        @include pocus() {
            color: $color-black;
        }
    }
}
